<template>
  <div class="page-box">
    <base-screen>
      <a-form-model ref="formRef" layout="inline" :model="params" @submit.native.prevent>
        <a-form-model-item label="用户昵称" prop='nickName'>
          <a-input v-model="params.nickName" placeholder="请输入" />
        </a-form-model-item>
        <a-form-model-item label="所属上级" prop='partnerName'>
          <a-input v-model="params.partnerName" placeholder="请输入" />
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" @click="handleSearch">查询</a-button>
          <a-button @click="handleReset">重置</a-button>
        </a-form-model-item>
      </a-form-model>
    </base-screen>
    <base-table bordered :columns="columns" :dataSource="dataRef" :pagination="pageRtv" :rowKey="record => record.userId" :loading="loadingRef" @change="handleTableChange">
      <template slot="user" slot-scope="text,record">
        <div class='user-box'>
          <base-img class="head-img" :src="record.headImg" />
          <div>{{record.nickName}}</div>
        </div>
      </template>
    </base-table>
  </div>
</template>

<script>
import { ref, reactive, toRefs, watch } from '@vue/composition-api'
import { useTableList } from '@/hooks'
import { system } from '@/api'
import { SHOPID } from '@/constants'
import { getSession } from '@/utils/session'
const columns = [
  // {
  //   title: '用户ID',
  //   width: 200,
  //   dataIndex: 'userId',
  // },
  {
    title: '用户',
    dataIndex: 'user',
    scopedSlots: { customRender: 'user' },
  },
  {
    title: '性别',
    dataIndex: 'sex',
  },
  {
    title: '手机号',
    dataIndex: 'phone',
    customRender: (x) => {
      return x || '-'
    },
  },
  {
    title: '所属地区',
    dataIndex: 'area',
    customRender: (x) => {
      return x || '-'
    },
  },
  {
    title: '从属关系',
    dataIndex: 'partnerName',
    customRender: (x) => {
      return x || '-'
    },
  },
  {
    title: '订单数量',
    dataIndex: 'orderNum',
  },
  {
    title: '支付金额',
    dataIndex: 'payAmount',
  },
]
export default {
  name: 'PageUserManagement',
  setup (props, { root }) {
    const state = reactive({
      params: {
        shopId: getSession(SHOPID),
        nickName: '',
        partnerName: '',
      },
      formRef: null,
      baseUrl: process.env.VUE_APP_IMG,
    })
    const labelCol = { span: 5 }
    const wrapperCol = { span: 17 }
    const { dataRef, pageRtv, loadingRef, setPage, resetPage, refresh } = useTableList(
      system.getUserList,
      ref(state.params)
    )

    function handleTableChange ({ current }) {
      setPage(current)
    }

    function handleSearch () {
      resetPage()
      refresh()
    }

    function handleReset () {
      state.formRef.resetFields()
      handleSearch()
    }

    return {
      ...toRefs(state),
      wrapperCol,
      labelCol,
      columns,
      dataRef,
      pageRtv,
      loadingRef,
      handleReset,
      handleSearch,
      handleTableChange,
    }
  },
}
</script>

<style lang="less" scoped>
.user-box {
  display: flex;
  align-items: center;
  .head-img {
    width: 60px;
    height: 60px;
    display: block;
    margin-right: 8px;
  }
}
::v-deep .img-default {
  width: 100%!important;
  height: 100%;
}
</style>
